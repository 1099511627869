var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"eedit-vaccine-sidebar","centered":"","visible":_vm.isEditVaccineSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":"","title":_vm.$t('editAnimalVaccine'),"ok-disabled":!_vm.blankVaccineData.vaccineid ||
      !_vm.blankVaccineData.vaccinedate ||
      !_vm.blankVaccineData.applier ||
      !_vm.blankVaccineData.cost ||
      !_vm.blankVaccineData.doz},on:{"ok":function () { return _vm.onSubmit(_vm.blankVaccineData); },"hidden":function($event){_vm.formValidation(_vm.resetblankvaccine).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit(_vm.blankVaccineData))},"reset":function($event){$event.preventDefault();_vm.formValidation(_vm.resetblankvaccine).resetForm}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"name":"vaccinedate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('vaccineDateTime'),"label-for":"vaccinedate"}},[_c('b-form-datepicker',{attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.blankVaccineData.vaccinedate),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "vaccinedate", $$v)},expression:"blankVaccineData.vaccinedate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"applier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('applier'),"label-for":"applier"}},[_c('b-form-input',{attrs:{"id":"applier","autofocus":"","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVaccineData.applier),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "applier", $$v)},expression:"blankVaccineData.applier"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"vaccine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('vaccine'),"label-for":"vaccine"}},[_c('v-select',{staticClass:"w-100",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.vaccines,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankVaccineData.vaccineid),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "vaccineid", $$v)},expression:"blankVaccineData.vaccineid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dose"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('dose') + ' (' + _vm.$t('left') + ' ' + _vm.stockLeft + ')',"label-for":"dose"}},[_c('b-form-input',{attrs:{"id":"dose","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.01"},model:{value:(_vm.blankVaccineData.doz),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "doz", $$v)},expression:"blankVaccineData.doz"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"cost"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('cost'),"label-for":"cost"}},[_c('b-form-input',{attrs:{"id":"cost","autofocus":"","disabled":"","type":"number","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.001"},model:{value:(_vm.blankVaccineData.cost),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "cost", $$v)},expression:"blankVaccineData.cost"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(!_vm.hasCostForVaccine)?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100 mt-2 bg-danger text-white rounded"},[_c('div',{staticClass:"p-1"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"AlertTriangleIcon","stroke-width":"2","size":"25"}}),_c('span',{},[_vm._v(_vm._s(!_vm.hasCostForVaccine ? _vm.$t("firstAddVaccine") : "")+". "+_vm._s(Math.abs(_vm.stockLeft))+" "+_vm._s(_vm.$t("addToStock"))+" ")])],1),_c('b-button',{staticClass:"mr-1 p-0",attrs:{"variant":"outline-light","type":"button","size":"sm"},on:{"click":function($event){_vm.hasCostForVaccine = !_vm.hasCostForVaccine}}},[_c('feather-icon',{attrs:{"icon":"XIcon","stroke-width":"2","size":"15"}})],1)],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }