import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useVaccineList() {
  // Use toast
  const toast = useToast();

  const refVaccineListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "vaccinedate",
      label: VueI18n.t("vaccineDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    {
      key: "applier",
      label: VueI18n.t("applier"),
      sortable: false,
    },
    {
      key: "doz",
      label: VueI18n.t("dose"),
      sortable: false,
    },
    {
      key: "cost",
      label: VueI18n.t("cost"),
      sortable: false,
    },
    {
      key: "vaccineid",
      label: VueI18n.t("vaccine"),
      sortable: false,
      formatter: (val) => {
        const vaccine = store.state.definitionsModule.vaccineData.filter(
          (item) => {
            return item.id == val;
          }
        );
        return vaccine[0]?.vaccineName ?? "";
      },
    },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalVaccine = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allVaccines = ref([]);
  const filteredVaccines = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredVaccines.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allVaccines.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredVaccines.value = allVaccines.value.filter((x) =>
        x.vaccinename.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredVaccines.value = allVaccines.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchVaccines = async (ctx) => {
    var vaccine = await store
      .dispatch("animalsModule/fetchAnimalVaccines", ctx)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("vaccines"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allVaccines.value = vaccine;
    filteredVaccines.value = [...vaccine];
    refetchData();

    return vaccine;
  };

  const deleteVaccine = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalVaccine", ctx.vaccineid)
      .then((_response) => {
        fetchVaccines(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("vaccines") }),
          },
        });
      })
      .catch((_e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("vaccines"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchVaccines,
    tableColumns,
    perPage,
    currentPage,
    totalVaccine,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVaccineListTable,
    deleteVaccine,
    filteredVaccines,
    allVaccines,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
