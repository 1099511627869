<template>
  <div>
    <b-modal
      id="add-new-medicine-sidebar"
      centered
      :visible="isAddNewMedicineSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-disabled="
        !blankMedicine.medicineid ||
        !blankMedicine.medicinedate ||
        !blankMedicine.applier ||
        !blankMedicine.cost ||
        !blankMedicine.doz
      "
      :title="$t('medicineAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankMedicine)"
      @hidden="formValidation(resetblankMedicine).resetForm"
      @change="(val) => $emit('update:is-add-new-medicine-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" :ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- medicinedate -->
          <validation-provider
            #default="validationContext"
            name="medicinedate"
            rules="required"
          >
            <b-form-group
              :label="$t('medicineDateTime')"
              label-for="medicinedate"
            >
              <b-form-datepicker
                v-model="blankMedicine.medicinedate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Applier -->
          <validation-provider
            #default="validationContext"
            name="applier"
            rules="required"
          >
            <b-form-group :label="$t('applier')" label-for="applier">
              <b-form-input
                id="applier"
                v-model="blankMedicine.applier"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Medicine -->
          <validation-provider
            #default="validationContext"
            name="medicine"
            rules="required"
          >
            <b-form-group :label="$t('medicine')" label-for="medicine">
              <v-select
                v-model="blankMedicine.medicineid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="medicines"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- doz -->
          <validation-provider #default="validationContext" name="dose">
            <b-form-group
              :label="$t('dose') + ' (' + $t('left') + ' ' + stockLeft + ')'"
              label-for="dose"
            >
              <b-form-input
                id="dose"
                v-model="blankMedicine.doz"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cost -->
          <validation-provider
            #default="validationContext"
            name="cost"
            rules="required"
          >
            <b-form-group :label="$t('cost')" label-for="cost">
              <b-form-input
                id="cost"
                v-model="blankMedicine.cost"
                autofocus
                disabled
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.001"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-if="!hasCostForMedicine"
            class="d-flex align-items-center justify-content-between w-100 mt-2 bg-danger text-white rounded"
          >
            <div class="p-1">
              <feather-icon
                icon="AlertTriangleIcon"
                class="mr-1"
                stroke-width="2"
                size="25"
              />
              <span class=""
                >{{ !hasCostForMedicine ? $t("firstAddMedicine") : "" }}.
                {{ Math.abs(stockLeft) }}
                {{ $t("addToStock") }}
              </span>
            </div>
            <b-button
              variant="outline-light"
              type="button"
              size="sm"
              class="mr-1 p-0"
              @click="hasCostForMedicine = !hasCostForMedicine"
            >
              <feather-icon icon="XIcon" stroke-width="2" size="15" />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import VSelect from "vue-select";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    VSelect,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMedicineSidebarActive",
    event: "update:is-add-new-medicine-sidebar-active",
  },
  props: {
    isAddNewMedicineSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
    stockMedicines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const medicines = ref([]);
    const hasCostForMedicine = ref(true);
    const stockLeft = ref(0);
    const blankMedicineData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      medicinedate: dayJs().format(),
      applier: "",
      medicineid: 0,
      doz: 1,
      cost: "",
    };
    let meds = [];
    const getMedicineList = async () => {
      meds = await store.dispatch("definitionsModule/fetchMedicines");
      medicines.value = meds
        .filter((x) => x.companyid != -1)
        .map((m) => ({
          label: m.medicineName,
          value: m.id,
        }));
    };

    getMedicineList();

    const blankMedicine = ref(JSON.parse(JSON.stringify(blankMedicineData)));
    const resetblankMedicine = () => {
      blankMedicine.value = JSON.parse(JSON.stringify(blankMedicineData));
    };

    watch(
      () => [blankMedicine.value.medicineid, blankMedicine.value.doz],
      ([medicineid, dose]) => {
        const medicine = meds.find((x) => x.id == medicineid);
        let currentDose =
          props.stockMedicines.filter((x) => x.id == medicine?.id)[0] ?? null;
        var usedDose = currentDose?.doz ?? 0;
        var leftDose = medicine?.stokmiktari - usedDose - dose;
        stockLeft.value = leftDose;
        if (medicine)
          if (
            medicine.cost != "" &&
            medicine.cost != null &&
            medicine.cost != 0 &&
            leftDose >= 0
          ) {
            blankMedicine.value.cost = (medicine.cost * dose).toFixed(
              getUserData().pricedecimal
            );
            hasCostForMedicine.value = true;
          } else {
            blankMedicine.value.cost = "";
            hasCostForMedicine.value = false;
          }
      },
      { immediate: true }
    );

    const onSubmit = (medicine) => {
      store
        .dispatch("animalsModule/addAnimalMedicine", medicine)
        .then((res) => {
          if (res.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.statusdata == "1") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-add-new-medicine-sidebar-active", false);
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalMedicineNotAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankMedicine);
    return {
      blankMedicine,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      medicines,
      hasCostForMedicine,
      resetblankMedicine,
      formValidation,
      stockLeft,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-medicine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
