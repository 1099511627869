<template>
  <div>
    <b-card no-body class="mb-0">
      <b-tabs class="mt-2 ml-2" content-class="ml-n2" active pills>
        <b-tab :title="$t('medicine')">
          <animal-list-medicine
            v-if="animal.id != null"
            :key="animal.id"
            :selected-animal="animal"
        /></b-tab>
        <b-tab :title="$t('vaccine')"
          ><animal-list-vaccine
            v-if="animal.id != null"
            :key="animal.id"
            :selected-animal="animal"
        /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from "bootstrap-vue";
import AnimalListMedicine from "./components/AnimalMedicine/AnimalListMedicine.vue";
import AnimalListVaccine from "./components/AnimalVaccine/AnimalListVaccine.vue";
import { mapState } from "vuex";
export default {
  components: { AnimalListMedicine, AnimalListVaccine, BCard, BTabs, BTab },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
};
</script>

<style></style>
