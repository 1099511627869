var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"eedit-medicine-sidebar","centered":"","visible":_vm.isEditMedicineSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":"","title":_vm.$t('editAnimalMedicine'),"ok-disabled":!_vm.blankMedicineData.medicineid ||
      !_vm.blankMedicineData.medicinedate ||
      !_vm.blankMedicineData.applier ||
      !_vm.blankMedicineData.cost ||
      !_vm.blankMedicineData.doz},on:{"ok":function () { return _vm.onSubmit(_vm.blankMedicineData); },"hidden":function($event){_vm.formValidation(_vm.resetblankmedicine).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit(_vm.blankMedicineData))},"reset":function($event){$event.preventDefault();_vm.formValidation(_vm.resetblankmedicine).resetForm}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"name":"medicinedate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('medicineDateTime'),"label-for":"medicinedate"}},[_c('b-form-datepicker',{attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.blankMedicineData.medicinedate),callback:function ($$v) {_vm.$set(_vm.blankMedicineData, "medicinedate", $$v)},expression:"blankMedicineData.medicinedate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"applier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('applier'),"label-for":"applier"}},[_c('b-form-input',{attrs:{"id":"applier","autofocus":"","state":_vm.formValidation(_vm.resetblankmedicine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankMedicineData.applier),callback:function ($$v) {_vm.$set(_vm.blankMedicineData, "applier", $$v)},expression:"blankMedicineData.applier"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"medicine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('medicine'),"label-for":"medicine"}},[_c('v-select',{staticClass:"w-100",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.medicines,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankMedicineData.medicineid),callback:function ($$v) {_vm.$set(_vm.blankMedicineData, "medicineid", $$v)},expression:"blankMedicineData.medicineid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dose"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('dose') + ' (' + _vm.$t('left') + ' ' + _vm.stockLeft + ')',"label-for":"dose"}},[_c('b-form-input',{attrs:{"id":"dose","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankmedicine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.01"},model:{value:(_vm.blankMedicineData.doz),callback:function ($$v) {_vm.$set(_vm.blankMedicineData, "doz", $$v)},expression:"blankMedicineData.doz"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"cost"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('cost'),"label-for":"cost"}},[_c('b-form-input',{attrs:{"id":"cost","autofocus":"","disabled":"","type":"number","state":_vm.formValidation(_vm.resetblankmedicine).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.001"},model:{value:(_vm.blankMedicineData.cost),callback:function ($$v) {_vm.$set(_vm.blankMedicineData, "cost", $$v)},expression:"blankMedicineData.cost"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(!_vm.hasCostForMedicine)?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100 mt-2 bg-danger text-white rounded"},[_c('div',{staticClass:"p-1"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"AlertTriangleIcon","stroke-width":"2","size":"25"}}),_c('span',{},[_vm._v(_vm._s(!_vm.hasCostForMedicine ? _vm.$t("firstAddMedicine") : "")+". "+_vm._s(Math.abs(_vm.stockLeft))+" "+_vm._s(_vm.$t("addToStock"))+" ")])],1),_c('b-button',{staticClass:"mr-1 p-0",attrs:{"variant":"outline-light","type":"button","size":"sm"},on:{"click":function($event){_vm.hasCostForMedicine = !_vm.hasCostForMedicine}}},[_c('feather-icon',{attrs:{"icon":"XIcon","stroke-width":"2","size":"15"}})],1)],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }