<template>
  <div>
    <b-modal
      id="eedit-medicine-sidebar"
      centered
      :visible="isEditMedicineSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalMedicine')"
      :ok-disabled="
        !blankMedicineData.medicineid ||
        !blankMedicineData.medicinedate ||
        !blankMedicineData.applier ||
        !blankMedicineData.cost ||
        !blankMedicineData.doz
      "
      @ok="() => onSubmit(blankMedicineData)"
      @hidden="formValidation(resetblankmedicine).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit(blankMedicineData))"
          @reset.prevent="formValidation(resetblankmedicine).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- medicinedate -->
          <validation-provider
            #default="validationContext"
            name="medicinedate"
            rules="required"
          >
            <b-form-group
              :label="$t('medicineDateTime')"
              label-for="medicinedate"
            >
              <b-form-datepicker
                v-model="blankMedicineData.medicinedate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Applier -->
          <validation-provider
            #default="validationContext"
            name="applier"
            rules="required"
          >
            <b-form-group :label="$t('applier')" label-for="applier">
              <b-form-input
                id="applier"
                v-model="blankMedicineData.applier"
                autofocus
                :state="
                  formValidation(resetblankmedicine).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Medicine -->
          <validation-provider
            #default="validationContext"
            name="medicine"
            rules="required"
          >
            <b-form-group :label="$t('medicine')" label-for="medicine">
              <v-select
                v-model="blankMedicineData.medicineid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="medicines"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- doz -->
          <validation-provider #default="validationContext" name="dose">
            <b-form-group
              :label="$t('dose') + ' (' + $t('left') + ' ' + stockLeft + ')'"
              label-for="dose"
            >
              <b-form-input
                id="dose"
                v-model="blankMedicineData.doz"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankmedicine).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cost -->
          <validation-provider #default="validationContext" name="cost">
            <b-form-group :label="$t('cost')" label-for="cost">
              <b-form-input
                id="cost"
                v-model="blankMedicineData.cost"
                autofocus
                disabled
                type="number"
                :state="
                  formValidation(resetblankmedicine).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                step="0.001"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-if="!hasCostForMedicine"
            class="d-flex align-items-center justify-content-between w-100 mt-2 bg-danger text-white rounded"
          >
            <div class="p-1">
              <feather-icon
                icon="AlertTriangleIcon"
                class="mr-1"
                stroke-width="2"
                size="25"
              />
              <span class=""
                >{{ !hasCostForMedicine ? $t("firstAddMedicine") : "" }}.
                {{ Math.abs(stockLeft) }}
                {{ $t("addToStock") }}
              </span>
            </div>
            <b-button
              variant="outline-light"
              type="button"
              size="sm"
              class="mr-1 p-0"
              @click="hasCostForMedicine = !hasCostForMedicine"
            >
              <feather-icon icon="XIcon" stroke-width="2" size="15" />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMedicineSidebarActive",
    event: "update:is-edit-medicine-sidebar-active",
  },
  props: {
    isEditMedicineSidebarActive: {
      type: Boolean,
      required: true,
    },
    medicine: {
      type: Object,
      required: true,
    },
    stockMedicines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankMedicineData: { ...this.medicine },
      imgStr: "",
      formValidation: formValidation,
      medicines: [],
      meds: [],
      hasCostForMedicine: true,
      stockLeft: 0,
    };
  },
  watch: {
    blankMedicineData: {
      handler: function (newVal, _oldVal) {
        var { medicineid, doz } = newVal;
        var medicine = this.meds.find((x) => x.id == medicineid);
        let currentDose = this.stockMedicines.filter(
          (x) => x.id == medicine.id
        )[0];
        var usedDose =
          currentDose?.id == this.medicine.medicineid
            ? (currentDose?.doz ?? 0) - this.medicine.doz
            : currentDose?.doz ?? 0;

        var leftDose = medicine.stokmiktari - usedDose - doz;

        this.stockLeft = leftDose;
        if (medicine)
          if (
            medicine.cost != "" &&
            medicine.cost != null &&
            medicine.cost != 0 &&
            leftDose >= 0
          ) {
            this.blankMedicineData.cost = (medicine.cost * doz).toFixed(
              getUserData().pricedecimal
            );
            this.hasCostForMedicine = true;
          } else {
            this.blankMedicineData.cost = "";
            this.hasCostForMedicine = false;
          }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getMedicineList();
  },
  async created() {},
  methods: {
    async getMedicineList() {
      this.meds = await store.dispatch("definitionsModule/fetchMedicines");
      this.medicines = this.meds
        .filter((x) => x.companyid != -1)
        .map((m) => ({
          label: m.medicineName,
          value: m.id,
        }));
    },
    async changed(val) {
      this.resetblankmedicine();
      this.$emit("update:is-edit-medicine-sidebar-active", val);
    },
    async onSubmit(data) {
      await store
        .dispatch("animalsModule/updateAnimalMedicine", data)
        .catch((err) => {});
      this.$emit("refetch-data");
      this.$emit("update:is-edit-medicine-sidebar-active", false);
    },
    async resetblankmedicine() {
      this.blankMedicineData = {
        ...this.medicine,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-medicine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
