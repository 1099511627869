<template>
  <div>
    <b-modal
      id="add-new-vaccine-sidebar"
      centered
      :visible="isAddNewVaccineSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-disabled="
        !blankVaccine.vaccineid ||
        !blankVaccine.vaccinedate ||
        !blankVaccine.applier ||
        !blankVaccine.cost ||
        !blankVaccine.doz
      "
      :title="$t('vaccineAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankVaccine)"
      @hidden="formValidation(resetblankVaccine).resetForm"
      @change="(val) => $emit('update:is-add-new-vaccine-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" :ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- vaccinedate -->
          <validation-provider
            #default="validationContext"
            name="vaccinedate"
            rules="required"
          >
            <b-form-group
              :label="$t('vaccineDateTime')"
              label-for="vaccinedate"
            >
              <b-form-datepicker
                v-model="blankVaccine.vaccinedate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Applier -->
          <validation-provider
            #default="validationContext"
            name="applier"
            rules="required"
          >
            <b-form-group :label="$t('applier')" label-for="applier">
              <b-form-input
                id="applier"
                v-model="blankVaccine.applier"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Vaccine -->
          <validation-provider
            #default="validationContext"
            name="vaccine"
            rules="required"
          >
            <b-form-group :label="$t('vaccine')" label-for="vaccine">
              <v-select
                v-model="blankVaccine.vaccineid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vaccines"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- doz -->
          <validation-provider #default="validationContext" name="dose">
            <b-form-group
              :label="$t('dose') + ' (' + $t('left') + ' ' + stockLeft + ')'"
              label-for="dose"
            >
              <b-form-input
                id="dose"
                v-model="blankVaccine.doz"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cost -->
          <validation-provider
            #default="validationContext"
            name="cost"
            rules="required"
          >
            <b-form-group :label="$t('cost')" label-for="cost">
              <b-form-input
                id="cost"
                v-model="blankVaccine.cost"
                autofocus
                disabled
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.001"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-if="!hasCostForVaccine"
            class="d-flex align-items-center justify-content-between w-100 mt-2 bg-danger text-white rounded"
          >
            <div class="p-1">
              <feather-icon
                icon="AlertTriangleIcon"
                class="mr-1"
                stroke-width="2"
                size="25"
              />
              <span class=""
                >{{ !hasCostForVaccine ? $t("firstAddVaccine") : "" }}.
                {{ Math.abs(stockLeft) }}
                {{ $t("addToStock") }}
              </span>
            </div>
            <b-button
              variant="outline-light"
              type="button"
              size="sm"
              class="mr-1 p-0"
              @click="hasCostForVaccine = !hasCostForVaccine"
            >
              <feather-icon icon="XIcon" stroke-width="2" size="15" />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import VSelect from "vue-select";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    VSelect,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewVaccineSidebarActive",
    event: "update:is-add-new-vaccine-sidebar-active",
  },
  props: {
    isAddNewVaccineSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
    stockVaccines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const vaccines = ref([]);
    const hasCostForVaccine = ref(true);
    const stockLeft = ref(0);
    const blankVaccineData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      vaccinedate: dayJs().format(),
      applier: "",
      vaccineid: 0,
      doz: 1,
      cost: "",
    };
    let meds = [];
    const getVaccineList = async () => {
      meds = await store.dispatch("definitionsModule/fetchVaccines");
      vaccines.value = meds
        .filter((x) => x.companyid != -1)
        .map((m) => ({
          label: m.vaccineName,
          value: m.id,
        }));
    };

    getVaccineList();

    const blankVaccine = ref(JSON.parse(JSON.stringify(blankVaccineData)));
    const resetblankVaccine = () => {
      blankVaccine.value = JSON.parse(JSON.stringify(blankVaccineData));
    };

    watch(
      () => [blankVaccine.value.vaccineid, blankVaccine.value.doz],
      ([vaccineid, dose]) => {
        const vaccine = meds.find((x) => x.id == vaccineid);
        let currentDose =
          props.stockVaccines.filter((x) => x.id == vaccine?.id)[0] ?? null;
        var usedDose = currentDose?.doz ?? 0;
        var leftDose = vaccine?.stokmiktari - usedDose - dose;
        stockLeft.value = leftDose;
        if (vaccine)
          if (
            vaccine.cost != "" &&
            vaccine.cost != null &&
            vaccine.cost != 0 &&
            leftDose >= 0
          ) {
            blankVaccine.value.cost = (vaccine.cost * dose).toFixed(
              getUserData().pricedecimal
            );
            hasCostForVaccine.value = true;
          } else {
            blankVaccine.value.cost = "";
            hasCostForVaccine.value = false;
          }
      },
      { immediate: true }
    );

    const onSubmit = (vaccine) => {
      store.dispatch("animalsModule/addAnimalVaccine", vaccine).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "1") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-vaccine-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalVaccineNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankVaccine);
    return {
      blankVaccine,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      vaccines,
      hasCostForVaccine,
      resetblankVaccine,
      formValidation,
      stockLeft,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-vaccine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
